import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions-container">
      <br />
      <br />
      <h1 style={{ textAlign: 'center', fontSize: '2.5rem', color: '#333' }}>Terms and Conditions</h1>

      <p>Last updated: 09/07/2024</p>

      <section className="terms-conditions-section">
        <h2>Introduction</h2>
        <p>Welcome to <strong>Chennai No. 1 Washing Machine Service Center</strong>. These Terms and Conditions govern your use of our website and the services we offer. By accessing our site, you agree to comply with these terms and conditions.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Services Provided</h2>
        <p><strong>Chennai No. 1 Washing Machine Service Center</strong> provides professional repair services for various home appliances, including washing machines, fridges, and other related services. Our services are available within the Chennai region, and we reserve the right to refuse service outside of the designated areas.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Use of the Website</h2>
        <p>By using our website, you agree to:</p>
        <ul>
          <li>Provide accurate and up-to-date information when requesting our services.</li>
          <li>Not engage in activities that could harm the functionality or integrity of our website.</li>
          <li>Not misuse or attempt to exploit any information provided on the website for malicious purposes.</li>
        </ul>
      </section>

      <section className="terms-conditions-section">
        <h2>Payment Terms</h2>
        <p>Payments for services rendered are due upon completion of the service. The accepted methods of payment include cash, credit cards, and online payment systems. We reserve the right to adjust pricing based on the complexity of the service provided.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Limitation of Liability</h2>
        <p><strong>Chennai No. 1 Washing Machine Service Center</strong> is not liable for any indirect, incidental, or consequential damages arising from the use or inability to use our services. Our liability is limited to the amount paid for the service in question.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Intellectual Property</h2>
        <p>All content on this website, including text, images, logos, and graphics, is the property of <strong>Chennai No. 1 Washing Machine Service Center</strong> or our licensors and is protected by copyright and intellectual property laws.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Modifications to Terms</h2>
        <p><strong>Chennai No. 1 Washing Machine Service Center</strong> reserves the right to modify these terms and conditions at any time. Any changes will be posted on this page, and the updated date will be noted at the top.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Governing Law</h2>
        <p>These Terms and Conditions are governed by the laws of India. Any legal disputes will be resolved in the courts located in Chennai, Tamil Nadu.</p>
      </section>

      <section className="terms-conditions-section">
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:chennaiwashingcare@gmail.com">chennaiwashingcare@gmail.com</a>.</p>
      </section>
    </div>
  );
};

export default TermsAndConditions;

import React from "react";
import './Footer.css';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
//import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';


function Footer() {
  return (
    <footer className="bg-dark text-white py-5">
      <div className="container">
        <div className="row">
          {/* Column 1: Home Appliance Content */}
          <div className="col-md-4 mb-4">
            <h5>Home Appliance Service Center </h5>
            <p>
              We offer top-notch services for all your home appliances including washing machines, air conditioners, refrigerators, and more. Our skilled team is committed to providing fast and reliable service to ensure your appliances run smoothly.
            </p>
          </div>

          {/* Column 2: Quick Links */}
          <div className="col-md-4 mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/" className="text-white">Home</a></li>
              <li><a href="/washing-machine-repair" className="text-white">Washing Machine</a></li>
              <li><a href="/ac-maintenance" className="text-white">AC</a></li>
              <li><a href="/fridge-repair" className="text-white">Fridge</a></li>
              <li><a href="/about" className="text-white">About Us</a></li>
              <li><a href="/contact" className="text-white">Contact</a></li>
            </ul>
          </div>

          {/* Column 3: Social Media & Contact */}
          <div className="col-md-4 mb-4">
            <h5>Connect With Us</h5>
            <ul className="list-unstyled">
              <li>
                <a href="https://wa.me/8489997666" className="text-white">
                  <i className="fab fa-whatsapp"></i> WhatsApp:+918489997666
                </a>
              </li>
              <li>
                <a href="mailto:chennaiwashingcare@gmail.com" className="text-white">
                  <i className="fas fa-envelope"></i> Email: chennaiwashingcare@gmail.com
                </a>
              </li>
              <li><p className="text-white">Address: 284, 2nd Cross Street, Eswari Nagar, Nagalkeni, Chromepet, Kancheepuram,Tami Nadu-600044</p></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center py-3">
        <p>&copy; 2024 Home Appliance Services. All rights reserved.</p>
        
      </div>

      <div className="container">
        <div className="row">
          {/* Left Column: Map */}
          <div className="col-md-6 map-container">
          <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249123.3498756345!2d79.56772235667156!3d12.837016352525327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52c4d59e6c207f%3A0x3b92f24576f3db0b!2sKanchipuram%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1730962055463!5m2!1sen!2sin"
  width="100%"
  height="400"
  style={{ border: 0 }} // Style must be an object
  allowFullScreen=""
  loading="lazy"
  title="Google Maps Location of Kanchipuram, Tamil Nadu"
/>


          </div>

          {/* Right Column: Legal Notices */}
          <div className="col-md-6 legal-notices">
            <div className="legal-column">
              <h3>Privacy Policy</h3>
              <Link to="/privacy-policy" className="legal-link">Read our Privacy Policy</Link>
            </div>

            <div className="disclaimer-container">
              <h3 className="disclaimer-title">Disclaimer:</h3>
              <p className="disclaimer-text">
                <strong>chennaino1washingmachine<br/>servicecenter</strong> is an independent service provider and is not affiliated with or authorized by any specific brand or manufacturer. We offer repair and maintenance services for a wide range of home appliances across multiple brands.
              </p>
            </div>

            <div className="legal-column">
              <h3>Terms & Conditions</h3>
              <Link to="/terms-and-conditions" className="legal-link">View Terms & Conditions</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from "react";
import "./ACRepair.css"; // Add a CSS file for styling
import heroImage from "../Images/image3.jpg"; 

const Achievements = () => {
  const achievements = [
    { id: 1, title: "Best Service Excellence Award 2023", description: "Awarded for maintaining the highest service standards in AC repairs and installations.", icon: "fas fa-award" },
    { id: 2, title: "1000+ Successful Repairs", description: "We have repaired over 1000 air conditioning units, restoring comfort to homes and offices.", icon: "fas fa-tools" },
    { id: 3, title: "Certified AC Technicians", description: "Our team consists of highly trained and certified technicians specializing in air conditioning systems.", icon: "fas fa-user-cog" },
    { id: 4, title: "Top-rated AC Service Provider", description: "Rated as the best AC repair service provider in the region by our satisfied customers.", icon: "fas fa-thumbs-up" },
  ];

  return (
    <div className="achievements-section">
      <h2>Our Achievements</h2>
      <div className="achievements-list">
        {achievements.map((achievement) => (
          <div className="achievement-item" key={achievement.id}>
            <div className="achievement-icon">
              <i className={achievement.icon}></i>
            </div>
            <h3>{achievement.title}</h3>
            <p>{achievement.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

function ACMaintenance() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "What types of air conditioners do you repair?",
      answer: "We repair all types of air conditioners, including split ACs, window ACs, and inverter models from leading brands.",
    },
    {
      question: "Why is my AC not cooling effectively?",
      answer: "Common reasons include dirty filters, low refrigerant levels, or faulty compressors. Our technicians can diagnose and resolve the issue efficiently.",
    },
    {
      question: "How do I book an AC repair service?",
      answer: "You can book a service by visiting our website or calling our helpline. We offer convenient time slots for your repair needs.",
    },
    {
      question: "How can I maintain my AC for optimal performance?",
      answer: "Clean the filters regularly, check for refrigerant leaks, and schedule periodic maintenance to keep your AC in top condition.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-container" style={{ backgroundImage: `url(${heroImage})` }}>
          <div className="hero-content">
            <h1 className="hero-title">Reliable AC Repair Services</h1>
            <p className="hero-description">
              Stay cool with our expert AC repair services. From minor fixes to major repairs, we ensure your air conditioner performs at its best.
            </p>
            <a href="tel:+918489997666" className="btn-book-now">Book Now</a>
          </div>
        </div>
      </section>

      <div className="about-us-section">
        <div className="about-us-content">
          <h2 className="about-us-title">About AC Experts</h2>
          <p className="about-us-description">
            At AC Experts, we specialize in air conditioning repair services for residential and commercial units. With skilled technicians and years of experience, we ensure fast and reliable repairs to keep you comfortable.
          </p>
          <p className="about-us-mission">
            <strong>Our Mission:</strong> To deliver efficient, affordable, and high-quality AC repair services with a focus on customer satisfaction.
          </p>
        </div>
      </div>

      <div className="issues-section">
        <h2 className="issues-title">Common AC Problems We Fix</h2>
        <p className="issues-description">
          Our technicians are skilled at addressing a variety of AC issues, including:
        </p>

        <div className="issues-container">
          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-thermometer-half"></i>
            </div>
            <h3 className="issue-title">Cooling Problems</h3>
            <p className="issue-description">
              Whether it’s uneven cooling or no cooling at all, we can diagnose and resolve the issue quickly.
            </p>
          </div>

          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-bolt"></i>
            </div>
            <h3 className="issue-title">Electrical Issues</h3>
            <p className="issue-description">
              From faulty wiring to malfunctioning thermostats, we handle all electrical AC problems.
            </p>
          </div>

          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-water"></i>
            </div>
            <h3 className="issue-title">Water Leakage</h3>
            <p className="issue-description">
              Fixing drainage problems and leaks to prevent damage to your AC unit and property.
            </p>
          </div>
        </div>
      </div>

      <section className="why-choose-us">
        <h2 className="section-title">Why Choose Us for AC Repair?</h2>
        <div className="benefits-container">
          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-clock"></i>
            </div>
            <h3 className="benefit-title">Prompt Service</h3>
            <p className="benefit-description">
              Our technicians provide fast and efficient repairs to minimize downtime and inconvenience.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-dollar-sign"></i>
            </div>
            <h3 className="benefit-title">Transparent Pricing</h3>
            <p className="benefit-description">
              Enjoy fair pricing with no hidden charges for all our services.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-tools"></i>
            </div>
            <h3 className="benefit-title">Skilled Technicians</h3>
            <p className="benefit-description">
              Certified professionals with extensive experience in AC repairs.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-smile"></i>
            </div>
            <h3 className="benefit-title">Customer Satisfaction</h3>
            <p className="benefit-description">
              Our top priority is ensuring you’re satisfied with our service.
            </p>
          </div>
        </div>
      </section>

      <Achievements />

      <div className="faq-section">
        <div className="container">
          <h2 className="faq-title">Frequently Asked Questions</h2>
          {faqData.map((faq, index) => (
            <div className={`faq-item ${activeIndex === index ? "open" : ""}`} key={index}>
              <button className="faq-question" onClick={() => handleToggle(index)}>
                {faq.question}
              </button>
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ACMaintenance;


// ContactSection.js
import React from "react";
import { FaPhoneAlt, FaMapMarkerAlt, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import "./ContactPage.css";  // ContactPage-specific styles
import contactImage from "../Images/CUSTOMER-SERVICE.jpg"; // Your contact image path

const Contact = () => {
  return (
    <>
      {/* Hero Section */}
      <div className="contact-hero-section" style={{ backgroundImage: `url(${contactImage})` }}>
        <div className="contact-hero-overlay">
          <div className="contact-hero-content">
            <h2 className="contact-hero-title">Contact Us</h2>
            <p className="contact-hero-description">
              Get in touch with <strong>Chennai Home Appliance Care</strong> for all your home appliance needs. We're here to help!
            </p>
            <button
              className="contact-btn"
              onClick={() => window.location.href = 'tel:+918489997666'}
            >
              Call Us Now:+918489997666
            </button>
          </div>
        </div>
      </div>

      {/* Contact Information */}
      <div className="contact-section">
        <div className="contact-column">
          <h3>Phone Number</h3>
          <p><FaPhoneAlt /> +91 8489997666</p>
          <p><FaPhoneAlt /> +91 8489997666</p>
        </div>

        <div className="contact-column">
          <h3>Address</h3>
          <p><FaMapMarkerAlt />284, 2nd Cross Street, Eswari Nagar, Nagalkeni, Chromepet, Kancheepuram,<br/>Tami Nadu-600044</p>
        </div>

        <div className="contact-column">
          <h3>Social Media</h3>
          <a href="https://wa.me/8489997666" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp /> WhatsApp
          </a>

          {/* Email Link */}
          <a href="mailto:chennaiwashingcare@gmail.com" target="_blank" rel="noopener noreferrer">
            <FaEnvelope /> Email
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;

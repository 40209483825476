import React, { useState } from "react";
import "./FridgeService.css"; // Updated CSS file for fridge service styling
import heroImage from "../Images/image2.jpg"; // Update to a fridge-related image

const Achievements = () => {
  const achievements = [
    { id: 1, title: 'Best Customer Support Award 2023', description: 'Recognized for providing exceptional customer service in appliance repair.', icon: 'fas fa-trophy' },
    { id: 2, title: '800+ Successful Repairs', description: 'Successfully repaired over 800 refrigerators in the last year.', icon: 'fas fa-cogs' },
    { id: 3, title: 'Expert Technicians', description: 'Our technicians are certified experts in fridge maintenance and repair.', icon: 'fas fa-user-cog' },
    { id: 4, title: 'Top-rated Service Provider', description: 'Rated as the best fridge service provider in the region.', icon: 'fas fa-star' },
  ];

  return (
    <div className="achievements-section">
      <h2>Our Achievements</h2>
      <div className="achievements-list">
        {achievements.map((achievement) => (
          <div className="achievement-item" key={achievement.id}>
            <div className="achievement-icon">
              <i className={achievement.icon}></i>
            </div>
            <h3>{achievement.title}</h3>
            <p>{achievement.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

function FridgeService() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: 'What types of refrigerators do you service?',
      answer: 'We service all types of refrigerators, including single-door, double-door, and side-by-side models from top brands like LG, Samsung, Whirlpool, and more.',
    },
    {
      question: 'Why is my fridge not cooling properly?',
      answer: 'This could be due to a faulty compressor, low refrigerant levels, or a clogged condenser coil. Contact us for a thorough inspection.',
    },
    {
      question: 'How do I book a fridge repair service?',
      answer: 'You can book a service online via our website or call our helpline. Our team will schedule a technician visit at your convenience.',
    },
    {
      question: 'What’s the best way to maintain my refrigerator?',
      answer: 'Keep the condenser coils clean, avoid overloading the fridge, and ensure proper door sealing. Regular maintenance helps extend your fridge’s life.',
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-container" style={{ backgroundImage: `url(${heroImage})` }}>
          <div className="hero-content">
            <h1 className="hero-title">Professional Fridge Repair Services</h1>
            <p className="hero-description">
              Quick and reliable refrigerator repair services to keep your appliance running efficiently.
              From cooling issues to electrical faults, we've got you covered!
            </p>
            <a href="tel:+918489997666" className="btn-book-now">Book Now</a>
          </div>
        </div>
      </section>

      <div className="about-us-section">
        <div className="about-us-content">
          <h2 className="about-us-title">About Hari Appliance Services</h2>
          <p className="about-us-description">
            At Hari Appliance Services, we specialize in providing expert fridge repair services. With years of experience, our skilled technicians ensure reliable and affordable solutions for all refrigerator models. 
          </p>
          <p className="about-us-mission">
            <strong>Our Mission:</strong> To deliver prompt and effective fridge repair services, ensuring 100% customer satisfaction and convenience.
          </p>
        </div>
      </div>

      <div className="issues-section">
        <h2 className="issues-title">Common Refrigerator Issues</h2>
        <p className="issues-description">
          Our technicians are adept at resolving various refrigerator issues. Here are some common problems we tackle:
        </p>

        <div className="issues-container">
          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-snowflake"></i> {/* Cooling icon */}
            </div>
            <h3 className="issue-title">Cooling Problems</h3>
            <p className="issue-description">
              If your fridge isn't cooling properly, we can diagnose and fix the issue efficiently.
            </p>
          </div>

          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-plug"></i> {/* Electrical icon */}
            </div>
            <h3 className="issue-title">Electrical Faults</h3>
            <p className="issue-description">
              Our team can handle issues like power surges, wiring problems, and faulty control panels.
            </p>
          </div>

          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-water"></i> {/* Water icon */}
            </div>
            <h3 className="issue-title">Water Leaks</h3>
            <p className="issue-description">
              We address problems like clogged defrost drains and water leakage from your fridge.
            </p>
          </div>
        </div>
      </div>

      <section className="why-choose-us">
        <h2 className="section-title">Why Choose Us for Fridge Repair?</h2>
        <div className="benefits-container">
          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-clock"></i>
            </div>
            <h3 className="benefit-title">Prompt Service</h3>
            <p className="benefit-description">
              We understand the urgency of fridge repairs and provide same-day service.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-dollar-sign"></i>
            </div>
            <h3 className="benefit-title">Competitive Pricing</h3>
            <p className="benefit-description">
              Enjoy top-notch repair services at an affordable price without hidden charges.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-tools"></i>
            </div>
            <h3 className="benefit-title">Skilled Technicians</h3>
            <p className="benefit-description">
              Our certified technicians are experts in repairing all refrigerator issues.
            </p>
          </div>

          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-thumbs-up"></i>
            </div>
            <h3 className="benefit-title">Customer First</h3>
            <p className="benefit-description">
              We prioritize your satisfaction and provide transparent service every time.
            </p>
          </div>
        </div>
      </section>

      <Achievements />

      <div className="faq-section">
        <h2 className="faq-title">Frequently Asked Questions</h2>
        {faqData.map((faq, index) => (
          <div className={`faq-item ${activeIndex === index ? 'open' : ''}`} key={index}>
            <button
              className="faq-question"
              onClick={() => handleToggle(index)}
            >
              {faq.question}
            </button>
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FridgeService;

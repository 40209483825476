import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./pages/Navbar";
import Home from "./pages/HeroSection";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import WashingMachineRepair from "./pages/WashingMachineRepair";
import ACMaintenance from "./pages/ACMaintenance";
import FridgeRepair from "./pages/FridgeRepair";
import './App.css';
import TermsAndConditions from './pages/TermsAndConditions.js';
import ScrollToTopButton from './pages/ScrollToTopButton .js'
import PrivacyPolicy from './pages/PrivacyPolicy.js';


function App() {
  return (
    <>
    <Router>
      <Navbar />
     <ScrollToTopButton />
      <Routes>
     
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/washing-machine-repair" element={<WashingMachineRepair />} />
        <Route path="/ac-maintenance" element={<ACMaintenance />} />
        <Route path="/fridge-repair" element={<FridgeRepair />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        
      </Routes>
      <Footer />
    </Router>


    <section id="fixed-call">
  <a href="tel:+918489997666" class="call-button">
    <span class="call-icon">📞</span> +918489997666
  </a>
</section>



{/* <section id="fixed-call">
  <a href="tel:+918489997666" class="call-button" aria-label="Call Us">
    <i class="fas fa-phone"></i>
  </a>
</section> */}
</>

  );
}

export default App;

import React from 'react';
import './AboutPage.css';
import aboutImage from '../Images/about_1.webp';

const About = () => {
  return (
    <div>
  
      <header className="about-header">
        <div className="container">
        <h1>About Us</h1>
<p>Welcome to chennaino1washingmachineservicecenter Home Appliance, your trusted partner for expert home appliance services. We specialize in providing professional repairs, maintenance, and installations for a range of home appliances, including washing machines, fridges, and air conditioners (ACs).</p>

<p>With years of experience in the industry, our certified technicians are committed to delivering high-quality solutions to ensure your appliances are running smoothly and efficiently. Whether you need a quick fix for your washing machine, a fridge repair, or an AC installation, we’re here to keep your home comfortable and hassle-free.</p>

<p>At Hari Home Appliance, customer satisfaction is our priority, and we strive to offer affordable, timely, and reliable services every time. No matter the issue, we provide trusted solutions tailored to your needs.</p>
</div>
      </header>

      {/* About Section */}
      <section className="about-section">
        <div className="container flex">
          <div className="about-content">
            <h2>Who We Are</h2>
            <p>
              We are a team of certified technicians specializing in air conditioning repairs,
              maintenance, and installations. With years of experience in the industry, we are
              committed to providing exceptional services that ensure your comfort and peace of mind.
            </p>
            <h3>Our Mission</h3>
            <p>
              To deliver top-notch air conditioning solutions that meet the highest standards of
              quality, efficiency, and customer satisfaction.
            </p>
          </div>
          <div className="about-image">
            {/* Use the imported image */}
            <img src={aboutImage} alt="Our Team" />
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <div className="container">
          <h2>Why Choose Us?</h2>
          <div className="flex">
            <div className="card">
              <div className="icon">🌟</div>
              <h3>Certified Technicians</h3>
              <p>Our experts are certified and experienced, ensuring reliable and quality service.</p>
            </div>
            <div className="card">
              <div className="icon">⏱</div>
              <h3>Timely Service</h3>
              <p>We respect your time by providing quick and efficient solutions.</p>
            </div>
            <div className="card">
              <div className="icon">💰</div>
              <h3>Affordable Pricing</h3>
              <p>We offer competitive rates without compromising on quality.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact CTA */}
      <section className="contact-cta">
        <div className="container">
          <h2>Have Questions?</h2>
          <p>Contact us today to learn more about our AC services or to book an appointment.</p>
         <a href="tel:+918489997666"> <button className="btn">Contact Us</button></a>
        </div>
      </section>

    </div>
  );
};

export default About;

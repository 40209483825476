import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
    
      <h1>Privacy Policy</h1>
      <p>Last updated: 09/07/2024</p>

      <section className="privacy-policy-section">
        <h2>Introduction</h2>
        <p>Welcome to <strong>Chennai No. 1 Washing Machine Service Center</strong>, your privacy is of utmost importance to us. This Privacy Policy document outlines the types of personal information that is received and collected by <strong>Chennai No. 1 Washing Machine Service Center</strong> and how it is used.</p>
</section>

      <section className="privacy-policy-section">
        <h2>Information We Collect</h2>
        <ul>
        <li>Personal Information: At <strong>Chennai No. 1 Washing Machine Service Center</strong>, we collect personal details such as your name, email, phone number, and address when you use our services.</li>
<li>Usage Data: <strong>Chennai No. 1 Washing Machine Service Center</strong> may collect information about how you access and use our website, including your IP address, browser type, and activity on the site.</li>

        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2>How We Use Your Information</h2>
        <p>The information we collect is used to:</p>
        <ul>
          <li>Provide and improve our services</li>
          <li>Contact you for customer support and updates</li>
          <li>Analyze usage data to improve user experience</li>
          <li>Comply with legal obligations</li>
        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2>Data Protection</h2>
        <p>We implement industry-standard security measures to protect your personal data from unauthorized access, alteration, and disclosure.</p>
      </section>

      <section className="privacy-policy-section">
        <h2>Third-Party Services</h2>
        <p>At <strong>Chennai No. 1 Washing Machine Service Center</strong>, we do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. However, we may share information with trusted third-party partners to perform services on our behalf, such as email services or payment processing.</p>
        </section>

      <section className="privacy-policy-section">
        <h2>Cookies</h2>
        <p>Our website uses cookies to enhance your browsing experience. You can modify your browser settings to refuse cookies, but this may affect the functionality of some website features.</p>
      </section>

      <section className="privacy-policy-section">
        <h2>Your Rights</h2>
        <p>At <strong>Chennai No. 1 Washing Machine Service Center</strong>, you have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact us at <a href="mailto:chennaiwashingcare@gmail.com">chennaiwashingcare@gmail.com</a>.</p>

      </section>

      <section className="privacy-policy-section">
        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. Any changes will be reflected on this page with an updated revision date.</p>
      </section>

      <section className="privacy-policy-section">
        <h2>Contact Us</h2>
        <p>If you have any questions regarding this Privacy Policy, feel free to contact us at <a href="mailto:chennaiwashingcare@gmail.com">chennaiwashingcare@gmail.com</a>. At <strong>Chennai No. 1 Washing Machine Service Center</strong>, we are always here to assist you.</p>

      </section>
    </div>
  );
};

export default PrivacyPolicy;

import React from "react";
import './Home.css';
import heroImage from '../Images/home_hero.webp';  // Adjust path accordingly
import washingMachineImg from '../Images/image1.webp';
import acImg from '../Images/image3.jpg';
import fridgeImg from '../Images/image2.jpg';


function Home() {
  return (
    
    <div>
      {/* Hero Section */}
      <div className="hero-container" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-content">
          <h1 className="hero-title">Welcome to Home Appliance Services</h1>
          <p className="hero-description">
            Your reliable partner for all home appliance needs. We offer quality
            service for washing machines, ACs, fridges, and more. Get your
            appliances running like new again!
          </p>
          <a href="tel:+918489997666" className="btn-book-now">Book Now</a>
        </div>
      </div>

      {/* About Us Section */}
      <section id="about" className="about-section">
  <div className="about-content">
    <h2 className="about-title">About Us</h2>
    <p className="about-description">
      At Home Appliance Services, we are dedicated to providing top-quality repair and maintenance services for all your household appliances. With years of experience and a team of skilled technicians, we ensure fast and reliable services for your washing machines, ACs, refrigerators, and more.
    </p>
    <p className="about-info">
      Whether it's a minor fix or a major repair, we're here to make your appliances work like new again. Our customer-first approach ensures that your needs are met with the highest standards of service. We're here to provide you with a reliable, fast, and professional service experience.
    </p>
  </div>
</section>
  {/* Our Services Section */}
  <section id="services" className="services-section">
        <h2>Our Services</h2>
        <div className="services-list">
          <div className="service-item">
            <div className="service-image" style={{ backgroundImage: `url(${washingMachineImg})` }}></div>
            <h3>Washing Machine Repair</h3>
            <p>We provide expert repair services for all types of washing machines, ensuring they function like new.</p>
          </div>
          <div className="service-item">
            <div className="service-image" style={{ backgroundImage: `url(${acImg})` }}></div>
            <h3>AC Maintenance</h3>
            <p>Stay cool with our air conditioning services. We offer installation, repair, and maintenance for all AC brands.</p>
          </div>
          <div className="service-item">
            <div className="service-image" style={{ backgroundImage: `url(${fridgeImg})` }}></div>
            <h3>Fridge Repairs</h3>
            <p>Is your fridge not cooling properly? Our experts are here to provide fast and efficient fridge repairs.</p>
          </div>
        </div>
      </section>
      <section id="best" class="py-5 bg-light">
  <div class="container text-center">
    <h1 class="display-4 text-primary fw-bold">"We Are the Best in Home Appliance Services"</h1>
    <p class="text-secondary">Serving Excellence in Washing Machine, Fridge, and Microwave Oven Services</p>

    <div class="row mt-5">
      
      <div class="col-md-4">
        <div class="card card-hover border-0 shadow">
          <div class="card-body">
            <h5 class="card-title text-primary">Washing Machine Service</h5>
            <p class="card-text">We provide quick and reliable solutions for all washing machine issues.</p>
          </div>
        </div>
      </div>
      
      <div class="col-md-4">
        <div class="card card-hover border-0 shadow">
          <div class="card-body">
            <h5 class="card-title text-primary">Fridge Service</h5>
            <p class="card-text">Our experts ensure your fridge runs efficiently at all times.</p>
          </div>
        </div>
      </div>
      
      <div class="col-md-4">
        <div class="card card-hover border-0 shadow">
          <div class="card-body">
          <h5 class="card-title text-primary">AC Service</h5>
<p class="card-text">We handle all AC repairs and maintenance with precision and care.</p>
</div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="why-us" class="py-5 bg-light">
  <div class="container text-center">
    <h2 class="display-4 text-primary fw-bold mb-4">Experience Excellence with Us</h2>
    <p class="text-secondary mb-5">We’re dedicated to delivering top-notch services with a customer-first approach. Here’s why we stand out.</p>

    <div class="row">

      <div class="col-md-4 mb-4">
        <div class="card card-hover border-0 shadow-lg rounded">
          <div class="card-body">
            <h5 class="card-title text-primary">Uncompromised Quality</h5>
            <p class="card-text">We deliver the highest quality service with meticulous attention to detail and professional care. Every repair, every fix, every time.</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card card-hover border-0 shadow-lg rounded">
          <div class="card-body">
            <h5 class="card-title text-primary">Affordable Pricing</h5>
            <p class="card-text">Our services come with transparent pricing to ensure you get the best value for your money without hidden charges or surprises.</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card card-hover border-0 shadow-lg rounded">
          <div class="card-body">
            <h5 class="card-title text-primary">24/7 Availability</h5>
            <p class="card-text">We understand that appliance issues can arise at any time. That's why we offer round-the-clock service to keep your home running smoothly.</p>
          </div>
        </div>
      </div>
    </div>


    <div class="mt-5">
      <a href="tel:+918489997666" class="btn btn-primary btn-lg">Reach Out to Us</a>
    </div>
  </div>
</section>





<section id="home-appliance-services" class="py-5 bg-light">
  <div class="container text-center">
    <h2 class="display-4 text-primary fw-bold mb-4">Top-Quality Home Appliance Services</h2>
    <p class="text-secondary mb-5">Our professional team is here to provide the best home appliance repair and maintenance services. We care for your appliances like they're our own!</p>

    <div class="row">

      <div class="col-md-4 mb-4">
        <div class="card card-hover border-0 shadow-lg rounded">
          <div class="card-body text-center">
            <i class="fas fa-tint fa-3x text-primary mb-3"></i>
            <h5 class="card-title text-primary">Washing Machine Repair</h5>
            <p class="card-text">We specialize in quick and efficient washing machine repairs. Whether it's a drum issue or electrical fault, our experts will fix it right away.</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card card-hover border-0 shadow-lg rounded">
          <div class="card-body text-center">
            <i class="fas fa-snowflake fa-3x text-primary mb-3"></i>
            <h5 class="card-title text-primary">Fridge Repair</h5>
            <p class="card-text">Our technicians offer fast and reliable fridge repair services. From cooling issues to compressor problems, we have the expertise to handle it all.</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div class="card card-hover border-0 shadow-lg rounded">
          <div class="card-body text-center">
            <i class="fas fa-oven fa-3x text-primary mb-3"></i>
            <h5 class="card-title text-primary">AC Repair</h5>
<p class="card-text">AC not cooling properly? We provide professional repair services to restore your AC’s performance quickly and affordably.</p>
</div>
        </div>
      </div>
    </div>


    <div class="mt-5">
      <h3 class="text-primary mb-3">Why Choose Our Customer Care?</h3>
      <p class="text-secondary mb-4">Our customer care is always ready to assist you with any queries or concerns. We value your satisfaction above all else!</p>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="card card-hover border-0 shadow-lg rounded">
            <div class="card-body text-center">
              <i class="fas fa-headset fa-3x text-primary mb-3"></i>
              <h5 class="card-title text-primary">24/7 Support</h5>
              <p class="card-text">Our customer support team is available around the clock to assist you with any issues you may face with your appliances.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-hover border-0 shadow-lg rounded">
            <div class="card-body text-center">
              <i class="fas fa-phone-volume fa-3x text-primary mb-3"></i>
              <h5 class="card-title text-primary">Easy Communication</h5>
              <p class="card-text">Reach us anytime via phone, email, or our online platform. We believe in providing hassle-free communication with our clients.</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="mt-5">
      <a href="tel:+918489997666" class="btn btn-primary btn-lg">Get Your Appliances Serviced</a>
    </div>
  </div>
</section>

   
    </div>
  );
}

export default Home;

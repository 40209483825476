import React, { useState }  from "react";
import "./WashingMachineRepair.css"; // Add a CSS file for styling
import heroImage from "../Images/wash_hero.jpg"; 
const Achievements = () => {





  const achievements = [
    { id: 1, title: 'Best Customer Satisfaction Award 2023', description: 'We received the award for maintaining the highest customer satisfaction ratings across our service areas.', icon: 'fas fa-trophy' },
    { id: 2, title: '500+ Successful Installations', description: 'We have successfully installed over 500 appliances in the last year, ensuring high-quality service and customer trust.', icon: 'fas fa-cogs' },
    { id: 3, title: 'Industry-Leading Technicians', description: 'Our team consists of certified and highly skilled technicians trained to handle all home appliance issues.', icon: 'fas fa-user-cog' },
    { id: 4, title: 'Top-rated Service Center', description: 'We are rated as the top service center in our region based on customer reviews and service quality.', icon: 'fas fa-star' },
  ];

  return (
    <div className="achievements-section">
      <h2>Our Achievements</h2>
      <div className="achievements-list">
        {achievements.map(achievement => (
          <div className="achievement-item" key={achievement.id}>
            <div className="achievement-icon">
              <i className={achievement.icon}></i>
            </div>
            <h3>{achievement.title}</h3>
            <p>{achievement.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

// Main WashingMachineRepair Component
function WashingMachineRepair() {
  
   // Initialize FAQ state to manage open/close of each question
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: 'What types of washing machines do you repair?',
      answer: 'We repair all types of washing machines, including top-load, front-load, and semi-automatic models from leading brands like Samsung, LG, Whirlpool, and more.',
    },
    {
      question: 'My washing machine isn’t draining water. What should I do?',
      answer: 'If your washing machine isn’t draining, it could be due to a blocked drain hose or a faulty pump. First, check the drain hose for clogs. If the problem persists, you may need a professional technician to inspect the pump.',
    },
    {
      question: 'How do I schedule a washing machine repair service?',
      answer: 'You can schedule a repair service by visiting our website and filling out the service request form, or by calling our helpline. Our technician will visit at a convenient time for you.',
    },
    {
      question: 'How can I maintain my washing machine for longer life?',
      answer: 'To keep your washing machine running smoothly, clean the drum and detergent drawer regularly, check and clean the filter, and avoid overloading the machine. Additionally, run an empty cycle with a washing machine cleaner every few months.',
    },
  ];
  

  const handleToggle = (index) => {
    // Toggle the active question based on index
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-container" style={{ backgroundImage: `url(${heroImage})` }}>
          <div className="hero-content">
            <h1 className="hero-title">Expert Washing Machine Repair Services</h1>
            <p className="hero-description">
              Fast, reliable, and affordable washing machine repair services for all brands.
              From drum issues to electrical faults, we’ve got you covered!
            </p>
            <a href="tel:+918489997666" className="btn-book-now">Book Now</a>
          </div>
        </div>
      </section>

      <div className="about-us-section">
        <div className="about-us-content">
          <h2 className="about-us-title">About Hari Home Appliance</h2>
          <p className="about-us-description">
            At Hari Home Appliance, we specialize in providing expert washing
            machine repair services. With years of experience in the industry, we
            have built a reputation for delivering reliable and affordable repair
            solutions for all washing machine brands. Our skilled technicians are
            dedicated to restoring your appliance to its optimal performance, 
            ensuring convenience and satisfaction for every customer.
          </p>
          <p className="about-us-mission">
            <strong>Our Mission:</strong> To offer fast, reliable, and affordable
            washing machine repair services, maintaining the highest standards of
            quality and customer satisfaction.
          </p>
        </div>
      </div>

      <div className="issues-section">
        <h2 className="issues-title">Common Washing Machine Issues</h2>
        <p className="issues-description">
          Our expert technicians are skilled at resolving various washing machine
          issues. Below are some of the most common problems we fix.
        </p>

        <div className="issues-container">
          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-wrench"></i> {/* Icon for repair */}
            </div>
            <h3 className="issue-title">Drum Issues</h3>
            <p className="issue-description">
              From unusual noises to drum rotation problems, our technicians quickly
              resolve all drum-related issues.
            </p>
          </div>

          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-plug"></i> {/* Icon for electrical */}
            </div>
            <h3 className="issue-title">Electrical Faults</h3>
            <p className="issue-description">
              We repair electrical faults like power failure, wiring problems, and
              faulty control boards to get your machine back in working order.
            </p>
          </div>

          <div className="issue-item">
            <div className="issue-icon">
              <i className="fas fa-tint"></i> {/* Icon for water leakage */}
            </div>
            <h3 className="issue-title">Water Leakage</h3>
            <p className="issue-description">
              If your washing machine has water leakage problems, we can identify
              the source and fix it immediately.
            </p>
          </div>
        </div>
      </div>

      <section className="why-choose-us">
        <h2 className="section-title">Why Choose Us for Washing Machine Repair?</h2>
        <div className="benefits-container">
          {/* Benefit 1 */}
          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-clock"></i> {/* Clock icon for speed */}
            </div>
            <h3 className="benefit-title">Fast Service</h3>
            <p className="benefit-description">
              We understand the urgency of having your washing machine repaired quickly. Our team is committed to fast response times and same-day repairs.
            </p>
          </div>

          {/* Benefit 2 */}
          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-dollar-sign"></i> {/* Dollar sign for affordability */}
            </div>
            <h3 className="benefit-title">Affordable Pricing</h3>
            <p className="benefit-description">
              Get the best value for your money with our transparent and affordable pricing. We offer high-quality repairs without breaking the bank.
            </p>
          </div>

          {/* Benefit 3 */}
          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-tools"></i> {/* Tools icon for expertise */}
            </div>
            <h3 className="benefit-title">Experienced Technicians</h3>
            <p className="benefit-description">
              Our technicians are highly trained and skilled in handling all types of washing machine issues. You can rely on our expertise to get your appliance working like new.
            </p>
          </div>

          {/* Benefit 4 */}
          <div className="benefit-card">
            <div className="benefit-icon">
              <i className="fas fa-thumbs-up"></i> {/* Thumbs up for satisfaction */}
            </div>
            <h3 className="benefit-title">Customer Satisfaction</h3>
            <p className="benefit-description">
              We prioritize customer satisfaction, ensuring that you’re fully satisfied with our service. We value your feedback and strive for excellence.
            </p>
          </div>
        </div>
      </section>

      {/* Include Achievements Section */}
      <Achievements />
      <div className="faq-section">
      <div className="container">
        <h2 className="faq-title">Frequently Asked Questions</h2>

        {faqData.map((faq, index) => (
          <div className={`faq-item ${activeIndex === index ? 'open' : ''}`} key={index}>
            <button
              className="faq-question"
              onClick={() => handleToggle(index)}
            >
              {faq.question}
            </button>
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    </div>
  );
}

export default WashingMachineRepair;
